import { Injectable } from '@angular/core';
import { ClaimTypeCommonService } from './claim-type-common.service';
import { ClaimTypeSGService } from './claim-type-sg.service';
import { ClaimTypeUSService } from './claim-type-us.service';
import { ClaimTypeAUService } from './claim-type-au.service';
import { ClaimTypeCAService } from './claim-type-ca.service';
import { ClaimTypeNzService } from './claim-type-nz.service';
import { CacheService } from 'src/app/shared/services/cache.service';

@Injectable({
  providedIn: 'root'
})
export class ClaimTypeClientService {
  constructor(private claimTypeSG: ClaimTypeSGService, private claimTypeUS: ClaimTypeUSService,
    private claimTypeCA: ClaimTypeCAService, private claimTypeAU: ClaimTypeAUService,
    private claimTypeNZ: ClaimTypeNzService, private claimCommon: ClaimTypeCommonService,
    private cacheService: CacheService) {


  }

  callService(): any {
    const country = this.cacheService.getCountry();
     if (country == 'us') {
      return this.claimTypeUS.getClaimTypeData()
    } else if (country == 'ca') {
      return this.claimTypeCA.getClaimTypeData()
    } else if (country == 'au') {
      return this.claimTypeAU.getClaimTypeData()
    } else if (country == 'nz') {
      return this.claimTypeNZ.getClaimTypeData()
    } else {
      return this.claimCommon.getClaimTypeData()
    }
  }
}
