import { Injectable } from '@angular/core';
import { DataStoreService } from 'src/app/features/fnol/services/data-store.service';
import { Configuration } from 'src/app/shared/configuration';

@Injectable({
  providedIn: 'root'
})
export class ClaimTypeSGService {
  step2: string;

  constructor( private config: Configuration,private dataStoreService:DataStoreService) { }

  getClaimTypeData() {
    if (this.dataStoreService.get(this.step2).uiData["claimBenefits"] === 'Travel') {
  //      return this.getTravelDetails(this.step2)
    }
    else if (this.dataStoreService.get(this.step2).uiData["claimBenefits"] === 'Other') {
     //   return this.getOtherDetails()
    }

    else if (this.dataStoreService.get(this.step2).uiData["claimBenefits"] === 'Personal Accident') {
   //     return this.personalAccidentPayload()
    }
}
}
