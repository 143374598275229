import { Component } from "@angular/core";
import { AppConfig } from "../../app/shared/config";
import { FnolService } from '../features/fnol/services/fnol.service';
import { Router } from "@angular/router";

@Component({
  selector: "cdp-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent {
  fnolCheck = true;

  country: string | null | undefined = window.location.pathname.split('/')[3];
  selectedLanguage: string | undefined;
  footerLinks: { label: string; link: string }[] = [
    {
      "label": "Terms Of Use",
      "link": "https://www.aig.com/terms-of-use"
    },
    {
      "label": "Privacy",
      "link": "https://www.aig.com/global-privacy-policy"
    },
    {
      "label": "Legal Notice",
      "link": "https://www.aig.com/legal-notice"
    },
    {
      "label": "Recommended Software",
       "link": "cdp-lite/supported"
    }
  ];
  copyrightText: string = "Copyright © 2024 American International Group, Inc. All rights reserved.";
  disclaimerText: string = "American International Group, Inc. (AIG) is a leading global insurance organization. AIG member companies offer insurance solutions that help businesses and individuals in approximately 70 countries and jurisdictions protect their assets and manage risks. AIG common stock is listed on the New York Stock Exchange.";

  constructor(
    public config: AppConfig,
    private fnolService: FnolService
  ) {

  }

  ngOnInit(): void {
    this.fnolService.landingSelectedCountry.subscribe(selectedCountry => {
      if (selectedCountry && AppConfig.countryConfig[selectedCountry.code.toLowerCase()]) {
        const country = selectedCountry.code.toLowerCase();
        this.footerLinks = AppConfig.countryConfig[country]['footer']['links'];
        this.copyrightText = AppConfig.countryConfig[country]['footer']['copyrightText'];
        this.disclaimerText = AppConfig.countryConfig[country]['footer']['disclaimerText'];
      }
    });
  }

  handleClick(url: string) {
    window.open(url, '_blank');
  }

}
