import { Injectable } from '@angular/core';
import { Configuration } from 'src/app/shared/configuration';
import { DataStoreService } from '../../../services/data-store.service';
import { FnolModelService } from '../../../services/fnol-model.service';
import * as moment from 'moment';
import { cdpLite } from './cdp-lite-model'
import { URL_PARAMETERS } from '../../../config/urlParameters.constant';
import { CHECKBOXHEADINGLITETravel, CHECKBOXLITEBAG, PAYMENTCHANNEL, LABELMAPPING, CHECKBOXLITEPERSONAL, FNOL_COUNTRY } from '../../../config/fnol-constants';
import { Utils } from 'axis-ui-generator';
import { AxisInputNumberPipe } from 'axis-components';
import { CacheService } from 'src/app/shared/services/cache.service';
import { FnolService } from '../../../services/fnol.service';
import { ClaimTypeClientService } from './claim-type-payload/claim-type-client.service';


@Injectable({
  providedIn: 'root'
})
export class CdpLiteModelService {
  momentDateFormat = 'DD-MM-YYYY';
  personalAccident = [];
  permanantdiablity;
  leavebenifit;
  otherClaimShow;
  accidentalDeatBoolean;
  foundmedical
  policyDetails;
  phoneCodeList;
  insuredDetails = null;
  step2 = 'fnol-claimtype-details';
  step1 = 'fnol-claimant-info';
  country;
  personalDetails = []
  hsotDrInfo = []
  data
  uiStructure: any;
  uiData: any;
  mySettings51: any = {
    view_roundoff: false,
    thousands_separator: ",",
    decimal_separator: ".",
    fraction_size: "2",
    reg_exp: "^\d{1,3}(,\d{3})*(\.\d{2})?$",
    decimal: "",
    restrict: false
  };
  constructor(
    private config: Configuration,
    private dataStoreService: DataStoreService,
    private fnolModelService: FnolModelService,
    public axisPipe: AxisInputNumberPipe,
    private fnolService: FnolService,
    public cacheService: CacheService,
    private claimType:ClaimTypeClientService
  ) {
    this.fnolService.landingSelectedCountry.subscribe(selectedCountry => {
      if (selectedCountry.code) {
        const country = selectedCountry.code.toLowerCase();
        this.country = selectedCountry.code.toLowerCase();
        if (config.countryConfig[country]["customDateFormat"]) {
          this.momentDateFormat = config.countryConfig[country]["customDateFormat"].split('/').join("-");
        }
      }
    });

  }

  setPolicyHolderDetails(details: any) {
    this.insuredDetails = {
      policyNo: details["policyNumber"],
      firstName: details["firstName"] || details["policyFirstName"],
      lastName: details["lastName"] || details["policyLastName"],
      dob: details["dateOfBirth"] || details["policyDateOfBirth"],
      nric: details["nricFin"] || details["policyNricFin"],
    };
  }

  getPrivacyPolicySectionInfo() {
    return [
      {
        name: "Privacy Policy:",
        value: "Yes",
      },
      {
        name: "Terms of Use:",
        value: "Yes",
      },
      {
        name: "By continuing with this form, I acknowledge and agree with both Privacy Policy and Terms of Use:",
        value: "Yes",
      },
    ];
  }

  

  convertDateGMT(datePassed, dateFormat: string, zeroCheck?): string {
    const dateFormatted = moment(datePassed).format(dateFormat);
    if (zeroCheck && zeroCheck === 'zeroTime') {
      return dateFormatted + 'T000000.000 GMT';
    } else { return dateFormatted + 'T050000.000 GMT'; }
  }

  getLossDate() {
    if (this.dataStoreService.get(this.step2).uiData["claimBenefits"] === 'Personal Accident') {
      const lossDate = this.convertDateGMT(
        this.dataStoreService.get(this.step2).uiData["accidentDate"],
        "YYYYMMDD"
      );
      return lossDate;
    }
    else if (this.dataStoreService.get(this.step2).uiData["claimBenefits"] === 'Travel') {
      const lossDate = this.convertDateGMT(
        this.dataStoreService.get(this.step2).uiData["dateSymptom"],
        "YYYYMMDD"
      );
      return lossDate;
    }

    else if (this.dataStoreService.get(this.step2).uiData["claimBenefits"] === 'Other') {
      const lossDate = this.convertDateGMT(
        this.dataStoreService.get(this.step2).uiData["otherDate"],
        "YYYYMMDD"
      );
      return lossDate;
    }
  }

  getLossDocDate() {
    if (this.dataStoreService.get(this.step2).uiData["claimBenefits"] === 'Personal Accident') {
      return this.dataStoreService.get(this.step2).uiData["accidentDate"]
    }
    else if (this.dataStoreService.get(this.step2).uiData["claimBenefits"] === 'Travel') {
      return this.dataStoreService.get(this.step2).uiData["dateSymptom"]
    }

    else if (this.dataStoreService.get(this.step2).uiData["claimBenefits"] === 'Other') {
      const currentDate = new Date().toString()
      return currentDate
    }
  }

  getDetails(requestName: string) {
    if (requestName === "policyNo") {
      if (this.policyDetails?.response?.policyDetails?.externalReference) {
        return this.policyDetails.response.policyDetails.externalReference;
      }
      else {
        return 'NOPOLICY';
      }
    }
  }

  getFnolRequestBody(
    type: string = "",
    policyDetailsData: any = {},
    phoneCodeListData: any = []
  ) {
    this.policyDetails = policyDetailsData;
    this.phoneCodeList = phoneCodeListData;

    cdpLite["applicationContext"]["userId"] =
      this.config.userEmailID;
    cdpLite["claimsDetails"]["userId"] = this.config.userEmailID;
    cdpLite["claimsDetails"]["claimNumber"] =
      this.fnolModelService.getClaimNumber();
    cdpLite["claimsDetails"]["dateofIncident"] =
      this.getLossDate();
    cdpLite.Icorr.iCorrespondence.correspondenceItem.correspondenceGenerationData.lossDate = this.getLossDate();
    cdpLite.applicationContext.countryCode = this.cacheService.getCountry().toUpperCase();
    cdpLite.Icorr.iCorrespondence.requestHeader.countryCode = this.cacheService.getCountry().toUpperCase();
    cdpLite.Icorr.iCorrespondence.ApplicationContext.country = this.cacheService.getCountry().toUpperCase();
    cdpLite.Icorr.iCorrespondence.correspondenceItem.distributionGenerationData.country = this.cacheService.getCountry().toUpperCase();
    cdpLite.Icorr.iCorrespondence.requestHeader.requestMessageID = this.fnolModelService.getClaimNumber();
    cdpLite.Icorr.iCorrespondence.correspondenceItem.pdfGenerationData.claimDetails.claimID = this.fnolModelService.getClaimNumber()

    if (
      (type === "setFnol")
    ) {
      return cdpLite;
    }
    let finalValue = this.prepDataForPDF(this.step1);
    if (URL_PARAMETERS.COUNTRY.toLowerCase() ===
      FNOL_COUNTRY.LITE.countryCode) {
      cdpLite.Icorr.iCorrespondence.correspondenceItem.pdfGenerationData.claimDetails.cdpDetails.info[0].attribute = finalValue
      cdpLite.Icorr.iCorrespondence.correspondenceItem.pdfGenerationData.claimDetails.cdpDetails.info[1].attribute = this.claimType.callService();
      cdpLite.Icorr.iCorrespondence.correspondenceItem.pdfGenerationData.claimDetails.cdpDetails.info[2].attribute = this.getPaymentData('fnol-payment-details');
      cdpLite.Icorr.iCorrespondence.correspondenceItem.pdfGenerationData.claimDetails.cdpDetails.info[3].attribute = this.getUploadDetails('fnol-upload-docs');
      cdpLite.Icorr.iCorrespondence.correspondenceItem.correspondenceGenerationData.claimantEmail = this.dataStoreService.get("fnol-claimant-info").uiData['email'];
      cdpLite.Icorr.iCorrespondence.correspondenceItem.correspondenceGenerationData.sharedServiceEmail = this.config.aigSharedeMailID
        ? this.config.aigSharedeMailID["sg"]
        : this.config.aigSharedeMailID;
      cdpLite.applicationContext.claimTypeCd = this.dataStoreService.get(this.step2).uiData["claimBenefits"];
      cdpLite.Icorr.iCorrespondence.correspondenceItem.correspondenceGenerationData.mobile = this.dataStoreService.get("fnol-claimant-info").uiData['contactNumber']['phoneCode'].hasOwnProperty('label') ? this.dataStoreService.get("fnol-claimant-info").uiData['contactNumber']['phoneCode']['label'] + "-" + this.dataStoreService.get("fnol-claimant-info").uiData['contactNumber']['phoneNumber'] : this.dataStoreService.get("fnol-claimant-info").uiData['contactNumber']['phoneCode'] + "-" + this.dataStoreService.get("fnol-claimant-info").uiData['contactNumber']['phoneNumber'];
      cdpLite.Icorr.iCorrespondence.correspondenceItem.correspondenceGenerationData.reportedByFirstName = this.dataStoreService.get("fnol-claimant-info").uiData['firstName'];
      cdpLite.Icorr.iCorrespondence.correspondenceItem.correspondenceGenerationData.reportedByLastName = this.dataStoreService.get("fnol-claimant-info").uiData['lastName'];
      cdpLite.Icorr.iCorrespondence.correspondenceItem.correspondenceGenerationData.insuredName = this.dataStoreService.get("fnol-claimant-info").uiData['firstName'] + " " + this.dataStoreService.get("fnol-claimant-info").uiData['lastName'];
      cdpLite.Icorr.iCorrespondence.correspondenceItem.correspondenceGenerationData.policyNumber = this.dataStoreService.get("fnol-claimant-info").uiData['policyNumber'];
    }
    return cdpLite;
  }

  decimalConverter(num: string, locale: string): string {
    let convert2Num: number = parseFloat(num);
    let decimalNum = convert2Num.toLocaleString(locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
    if (decimalNum.substring(decimalNum.length - 3)[0] === ',') {
      let decimalNumber = decimalNum.replace(decimalNum.substring(decimalNum.length - 3)[0], '.');
      return decimalNumber;
    } else {
      return decimalNum;
    }
  }

  getData(name, type, stepName) {
    let resultData
    this.uiStructure = this.dataStoreService.get(stepName).uiStructure;
    this.uiData = this.dataStoreService.get(stepName).uiData;
    try {
      resultData = Utils.getFieldFromStructure(this.uiStructure, name);
    }
    catch (error) {
      resultData = '';
      return resultData;
    }
    if (type == "label") {
      if (!resultData) {
        return resultData = '';
      }
      if (resultData?.type === "checkbox") {
        return {
          key: resultData?.heading,
          value: resultData?.questionCd,
        }
      }
      return resultData;
    } else if (type === "rpa") {
      if (resultData?.type === "dropdown") {
        if (resultData.hasOwnProperty('isMultiSearchSelect')) {
          return this.uiData[name].map(i => i.id).join(',')
        } else if (!resultData.hasOwnProperty('isMultiSearchSelect')) {
          return this.uiData[name]?.id
        }
      } else if (resultData?.type === "radio") {

        return this.uiData[name];
      }
      else if (resultData?.type === "checkbox") {

        return (this.uiData[name] != null && this.uiData[name] != '' && this.uiData[name] != 'No') ? "Yes" : "No"

      }

      else if (resultData?.type === "text" || resultData?.type === "datepicker" || resultData?.type === "timepicker" || resultData?.type === null || resultData?.type === "textarea") {
        return null
      }
    } else {
      if (resultData?.type === "datepicker" && this.uiData[name] != 'Invalid date' && this.uiData[name] != null && this.uiData[name] != '') {
        return (this.uiData[name] != null && this.uiData[name] != "") ? moment(new Date(this.uiData[name])).format(this.momentDateFormat) : this.uiData[name];
      } else if (resultData?.type === "timepicker") {
        return (this.uiData[name] != null && this.uiData[name] != "") && this.uiData[name]?.length > 5 ? moment(this.uiData[name]).format("hh:mm A") : this.uiData[name];
      } else if (resultData?.type === "checkbox") {
        return (this.uiData[name] != null && this.uiData[name] != '' && this.uiData[name] != 'No') ? $localize`:@@yesNor:Yes` : $localize`:@@noNor:No`
      }
      else if (resultData?.type === "dropdown") {

        if (resultData.hasOwnProperty('isMultiSearchSelect')) {
          return this.uiData[name].map(i => i.name).join(',')
        } else if (!resultData.hasOwnProperty('isMultiSearchSelect')) {
          return this.uiData[name]?.name
        }

      } else if (resultData?.type === "radio") {
        return this.uiData[name];
      }
      else {
        return this.uiData[name]
      }
    }
  }

  checkCustom(field, index, step) {
    if (this.getData(field + index, "label", step)['type'] === "custom" &&
      this.getData(field + index, "label", step)['element'] === "CurrencyDropdownComponent"
    ) {
      return this.getData(field + index, "", step)['phoneNumber'] ?
        this.getData(field + index, "", step)['phoneCode'].hasOwnProperty('label') ?
          this.getData(field + index, "", step)['phoneCode']['label'] + " " + this.axisPipe.transform(this.getData(field + index, "", step)['phoneNumber'].toString(), this.mySettings51) :
          this.getData(field + index, "", step)['phoneCode'] + " " + this.axisPipe.transform(this.getData(field + index, "", step)['phoneNumber'].toString(), this.mySettings51)
        : null
    }
    else {
      return this.getData(field + index, "", step)
    }
  }

  prepDataForPDF(step) {
    let personalInfo = ["heading", "policyNumber", "groupName", "empId", "firstName", "lastName", "dob", "currentAge"];
    let personalDetailsInfo = [];
    personalInfo.forEach(res => {

      if (res == "heading") {
        personalDetailsInfo.push(
          {
            name: 'Personal Information',
            value: 'heading'
          });
      } else {
        const value = this.getData(res, "", step);
        // if (value) {
        personalDetailsInfo.push(
          {
            name: this.getData(res, "label", step)['label'],
            value
          });
        // }
      }
    });

    let contactInfo = ["heading", "email", "contactNumber", "personalCountry", "myAddressLookup", "levelUnitNumber", "postalCode"]
    contactInfo.forEach(res => {
      if (res == "heading") {
        personalDetailsInfo.push(
          {
            name: 'Contact Information',
            value: 'heading'
          });
      } else {
        if (res === 'contactNumber' && this.uiData["contactNumber"] !== null) {
          personalDetailsInfo.push(
            {
              name: 'Contact Number',
              value: this.getData("contactNumber", "", step)['phoneCode'].hasOwnProperty('label') ? this.getData("contactNumber", "", step)['phoneCode']['label'] + "-" + this.getData("contactNumber", "", step)['phoneNumber'] : this.getData("contactNumber", "", step)['phoneCode'] + "-" + this.getData("contactNumber", "", step)['phoneNumber']
            });
        } else if (res === "personalCountry" && this.getData(res, "label", step)['type'] === "dropdown") {
          personalDetailsInfo.push({
            name: this.getData('personalCountry', "label", step)['label'],
            value: this.dataStoreService.get('fnol-claimant-info').uiData['personalCountry'].name
          });
        } else if (res === 'levelUnitNumber' && this.uiData['levelUnitNumber'] !== "") {
          personalDetailsInfo.push(
            {
              name: this.getData(res, "label", step)['label'],
              value: this.getData(res, "", step)
            })
        } else {
          // if (res !== 'levelUnitNumber' && this.uiData[res]?.trim() !== "") {
          personalDetailsInfo.push(
            {
              name: this.getData(res, "label", step)['label'],
              value: this.getData(res, "", step)
            });
          // }
        }
      }
    });
    return personalDetailsInfo
  }

  getClaimType(step) {
    let claimType = ["claimBenefits"]
    let claimTypeInfo = []
    claimType.forEach(fieldName => {
      claimTypeInfo.push(
        {
          name: this.getData(fieldName, "label", step)['label'],
          value: this.getData(fieldName, "", step)
        });
    })
    return claimTypeInfo;
  }

 
  getPaymentData(step) {
    const paymentObj = [];
    const paymentData = this.dataStoreService.get('fnol-payment-details').uiData;

    if (this.country !== "hk") {
      if (paymentData?.paymentType) {
        paymentObj.push({
          name: "Payment Channel",
          value: paymentData['paymentType'].value
        });
      } else {
        paymentObj.push({
          name: "Payment Channel",
          value: PAYMENTCHANNEL[this.country]
        });
      }
      if (paymentData?.claimExpenses) {
        paymentObj.push({
          name: "Are you claiming or intend to claim the expenses incurred from other insurance companies or organizations?",
          value: paymentData['claimExpenses'].value
        });
      }
      Object.keys(paymentData).forEach(item => {
        if (LABELMAPPING[item]) {
          paymentObj.push({
            name: LABELMAPPING[item],
            value: item == "ownerStructure" || item == "claimExpenses" ? paymentData[item]['value'] : item == 'claimInsured' ? paymentData[item] + "%" : paymentData[item]
          });
        }
      });
    } else {
      Object.keys(paymentData).forEach((item, index) => {
        if (index !== 1) {
          if (LABELMAPPING[item]) {
            paymentObj.push({
              name: LABELMAPPING[item],
              value: item == "ownerStructure" || item == "claimExpenses" ? paymentData[item]['value'] : item == 'claimInsured' ? paymentData[item] + "%" : paymentData[item]
            });
          }
        } else {
          paymentObj.push({
            name: "Are you claiming or intend to claim the expenses incurred from other insurance companies or organizations?",
            value: paymentData['claimExpenses'].value
          });
          paymentObj.push({
            name: "Payment Channel",
            value: paymentData['paymentType'].value
          });
        }
      });
    }


    return paymentObj;

  }

  getUploadDetails(step) {
    let obj = []
    if (this.dataStoreService.get(step).uiData.customFileUpload) {
      let fileList = this.dataStoreService.get(step).uiData.customFileUpload.uploads;
      fileList.forEach((element, i) => {
        obj.push(
          {
            name: "Upload" + (i + 1) + ":",
            value: element.name
          }
        );
      });
      return obj;
    } else {
      return obj;
    }
  }

 
  
  getDammageDelayDetails() {
    const nonBagNoEMp = ["nameClinic", "dateOfAdmission", "timeAdmission", "timeDischarge"]
    const travelArr = this.buildDetails({ mainUIFieldName: "addTreatment", fieldNames: nonBagNoEMp, conditionalFieldName: "admittedHospital" });
    return travelArr
  }

  buildDetails({
    mainUIFieldName,
    fieldNames,
    conditionalFieldName,
  }: {
    mainUIFieldName: string;
    fieldNames: string[];
    conditionalFieldName?: string
  }) {

    // array to return
    let formattedArray = [];
    // uiStructure fields

    const container = Utils.getFieldFromStructure(
      this.dataStoreService.get(this.step2).uiStructure,
      mainUIFieldName
    ).fields;
    const uiData = this.dataStoreService.get(this.step2).uiData;
    // loop over fields
    for (let i = 0; i < container.length; i++) {
      const matches = container[i].name.match(/(\d+)/);
      const digit = matches?.[0];

      // a main if condition that if "Yes", then do the rest
      if (conditionalFieldName) {
        if (uiData[conditionalFieldName] === "Yes") {
          // array of nested fields to look for
          // loop over nested fields
          formattedArray = this.pushFieldNames({ fieldNames, formattedArray, index: digit ? digit : "" });
        }
      } else {
        formattedArray = this.pushFieldNames({ fieldNames, formattedArray, index: digit ? digit : "" });
      }
    }
    // the main data
    // return array
    return formattedArray;
  }

  pushFieldNames({ fieldNames, formattedArray, index }: { fieldNames: string[]; formattedArray: any[]; index?: string }): { name: string; data: any }[] {
    // array of nested fields to look for
    // loop over nested fields
    fieldNames.forEach((name) => {
      // check for type "checkbox" is unnecessary
      // do one check to make sure data is there
      const data = this.getData(name + index, "", this.step2);
      if (data) {
        // then push to array
        formattedArray.push({
          name: this.getData(name + index, "label", this.step2)["label"],
          value: data,
        });
      }
    });
    return formattedArray;
  }


}
